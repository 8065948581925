import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainFiltersContainer: {
    width: '26.875rem',
    [theme.breakpoints.down(1270)]: {
      width: '100%',
    },
  },
  upper: {
    height: '16.875rem',
    '& .coolebraImage': {
      borderRadius: '20px',
    },
    '& .coolebraLogo': {
      position: 'relative',
      height: '3rem',
      top: '-7.6rem',
      left: '6rem',
      cursor: 'pointer',
    },
    [theme.breakpoints.down(1270)]: {
      borderRadius: '0px',
      '& .coolebraImage': {
        borderRadius: 0,
      },
      '& .coolebraLogo': {
        position: 'absolute',
        height: '2.6rem',
        top: '3.1rem',
        left: '7rem',
        cursor: 'pointer',
      },
    },
  },
  lower: {
    minHeight: '29.063rem',
    backgroundColor: '#F4F4F3',
    boxShadow: '4px 4px 20px 2px rgba(0, 0, 0, 0.2)',
    borderRadius: '20px',
    margin: '-5.5rem 0 0 0',
    position: 'relative',
    transition: 'all .4s',
    padding: '1.3rem 3.6rem 1.3rem 3.6rem',
    [theme.breakpoints.down(1270)]: {
      margin: '-7.5rem 0 5rem 0',
      padding: '1.3rem',
    },
  },
  backButton: {
    width: '3.25rem',
    height: '3.25rem',
    fontSize: '50px',
    backgroundColor: 'rgba(75, 31, 31,0.6)',
    position: 'relative',
    top: '-11rem',
    left: '3rem',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    [theme.breakpoints.down(1270)]: {
      position: 'absolute',
      top: '3rem',
    },
  },
  leftArrow: {},
  title: {
    fontSize: '1.5rem',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#393e44',
    paddingBottom: '0.6rem',
  },
  description: {
    fontSize: '0.875rem',
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    textAlign: 'left',
    color: '#6B6B6B',
    paddingBottom: '2rem',
    [theme.breakpoints.down(1270)]: {
      fontSize: '0.875rem',
      color: '#9D9D9D',
    },
  },
  vectorContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  vector: {
    height: '0.063rem',
    width: '100%',
    backgroundColor: '#D6D6D6',
    margin: '1rem 0',
  },
  filterButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.8rem',
  },
  filterTitle: {
    width: '12rem',
    fontSize: '0.875rem',
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    color: '#6B6B6B',
    textAlign: 'left',
  },
  productRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  CheckboxInput: {
    '& .css-ptiqhd-MuiSvgIcon-root': {
      color: theme.palette.primary.dark,
    },
  },
  filtersColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  filtersRow: {
    '& span': {
      color: '#7D7C7C',
      fontSize: '0.75rem',
      textAlign: 'left',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'normal',
    },
  },
  moreText: {
    marginTop: '0.5rem',
    textAlign: 'left',
    width: 'fit-content',
    fontSize: '0.75rem',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    color: '#7D7C7C',
    textDecoration: 'underline',
    textDecorationColor: '#7D7C7C',
  },
  discountContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '0 0.5rem 0 0.5rem',
    marginTop: '0.5rem',
    '& span': {
      fontSize: '0.75rem',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'normal',
      color: '#7D7C7C',
    },
  },
  discountInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '5rem',
    height: '1.8rem',
    borderRadius: '5.5px',
    border: '1px solid #7D7C7C',
  },
  discountInput: {
    width: '70%',
    height: '100%',
    borderRadius: '5px 0 0 5px',
    fontSize: '0.75rem',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    color: '#9D9D9D',
    textAlign: 'center',
  },
  percentageBox: {
    width: '30%',
    backgroundColor: '#9D9D9D',
    height: '100%',
    borderRadius: '0 5px 5px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      fontSize: '0.75rem',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'normal',
      color: '#FFFFFF',
    },
  },
  FiltrosDropdown: {
    display: 'flex',
    flexDirection: 'column',
  },
  hidden: {
    display: 'none',
  },
  collapsableIcon: {
    transform: 'rotate(-90deg)',
    transition: 'transform .3s',
  },
  collapsableMainIconOpen: {
    transform: 'rotate(90deg)',
    transition: 'transform .3s',
  },
  collapsableIconOpen: {
    transition: 'transform .3s',
  },
  mobileFilters: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'red',
    zIndex: 10,
  },
  upperMobileFilter: {
    height: '11rem',
    borderRadius: '20px',
    background: '#008296',
    [theme.breakpoints.down(1270)]: {
      borderRadius: '0px',
    },
  },
  backButtonFilterMobile: {
    width: '2.5rem',
    height: '2.5rem',
    fontSize: '35px',
    backgroundColor: 'rgba(75, 31, 31,0.6)',
    position: 'relative',
    top: '2.6rem',
    right: '-18rem',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
  },
  FilterTitleMobile: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    paddingRight: '5rem',
    '& span': {
      fontSize: '1.75rem',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      color: '#FFFFFF',
    },
    '& img': {
      height: '1.3rem',
    },
  },
  LowerMobileFilter: {
    minHeight: '37.063rem',
    backgroundColor: '#F4F4F3',
    boxShadow: '4px 4px 20px 2px rgba(0, 0, 0, 0.2)',
    borderRadius: '20px',
    margin: '-1.5rem 0 5rem 0',
    position: 'relative',
    transition: 'all .4s',
    padding: '1.3rem 3.6rem 1.3rem 3.6rem',
    [theme.breakpoints.down(1270)]: {
      padding: '1.3rem 1.6rem 5.3rem 1.6rem',
    },
  },
  weekdaysContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
  },
  weekdayButton: {
    borderRadius: '16.5px',
    fontSize: '0.75rem',
    fontWeight: 'normal',
    fontFamily: 'Montserrat',
  },
  applyButton: {
    borderRadius: '16.5px',
    fontSize: '1rem',
    fontWeight: 'normal',
    fontFamily: 'Montserrat',
    width: '100%',
  },
}));
