import React, { Dispatch, SetStateAction } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useAuth } from '@hooks';
import { useStyles } from './FilterIconRow.style';
import {
  COOLEBRA_DESTACADOS_ICON,
  COOLEBRA_PANORAMAS_ICON,
  COOLEBRA_PROMOCIONES_ICON,
  COOLEBRA_SABORES_ICON,
  COOLEBRA_SALUD_BELLEZA_ICON,
  COOLEBRA_SERVICIOS_ICON,
  COOLEBRA_SHOPPING_ICON,
  COOLEBRA_VIAJES_ICON,
} from 'images/GCP';
import { CoolebraFiltersTypes } from '@interfaces';

interface FilterIconRowProps {
  filters: CoolebraFiltersTypes;
  setFilters: Dispatch<SetStateAction<CoolebraFiltersTypes>>;
}

const FilterIconRow = ({ filters, setFilters }: FilterIconRowProps) => {
  useAuth({ redirectIfNotLogged: true });
  const classes = useStyles();
  return (
    <div className={classes.mainPromocontainer}>
      <div className={classes.iconRow}>
        <button
          className={classes.iconContainer}
          onClick={() => setFilters({ ...filters, categories: [] })}
        >
          <img className={classes.icon} src={COOLEBRA_DESTACADOS_ICON} alt="" />
          <p className={classes.iconRowText}>Todos</p>
        </button>
        <button
          className={classes.iconContainer}
          onClick={() => setFilters({ ...filters, categories: ['pano'] })}
        >
          <img className={classes.icon} src={COOLEBRA_PANORAMAS_ICON} alt="" />
          <p className={classes.iconRowText}>Panoramas</p>
        </button>
        <button
          className={classes.iconContainer}
          onClick={() => setFilters({ ...filters, categories: ['promo'] })}
        >
          <img
            className={classes.icon}
            src={COOLEBRA_PROMOCIONES_ICON}
            alt=""
          />
          <p className={classes.iconRowText}>Promociones</p>
        </button>
        <button
          className={classes.iconContainer}
          onClick={() => setFilters({ ...filters, categories: ['sabor'] })}
        >
          <img className={classes.icon} src={COOLEBRA_SABORES_ICON} alt="" />
          <p className={classes.iconRowText}>Sabores</p>
        </button>
        <button
          className={classes.iconContainer}
          onClick={() => setFilters({ ...filters, categories: ['serv'] })}
        >
          <img className={classes.icon} src={COOLEBRA_SERVICIOS_ICON} alt="" />
          <p className={classes.iconRowText}>Servicios</p>
        </button>
        <button
          className={classes.iconContainer}
          onClick={() => setFilters({ ...filters, categories: ['shop'] })}
        >
          <img className={classes.icon} src={COOLEBRA_SHOPPING_ICON} alt="" />
          <p className={classes.iconRowText}>Shopping</p>
        </button>
        <button
          className={classes.iconContainer}
          onClick={() => setFilters({ ...filters, categories: ['salud'] })}
        >
          <img
            className={classes.icon}
            src={COOLEBRA_SALUD_BELLEZA_ICON}
            alt=""
          />
          <p className={classes.iconRowText}>Salud y belleza</p>
        </button>
        <button
          className={classes.iconContainer}
          onClick={() => setFilters({ ...filters, categories: ['viaje'] })}
        >
          <img className={classes.icon} src={COOLEBRA_VIAJES_ICON} alt="" />
          <p className={classes.iconRowText}>Viajes</p>
        </button>
      </div>
    </div>
  );
};

export default FilterIconRow;
