import React, { Dispatch, SetStateAction, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useAuth } from "@hooks";
import SingleCard from "../SingleCard/SingleCard";
import { useStyles } from "./CoolebraPromoCards.style";
import FilterIconRow from "../IconRow/FilterIconRow";
import { CoolebraFiltersTypes, CoolebraTypeAll } from "interfaces/coolebraTypeAll";

interface CoolebraPromoCardsProps{
  dataToShow: CoolebraTypeAll[];
  filters: CoolebraFiltersTypes;
  setFilters: Dispatch<SetStateAction<CoolebraFiltersTypes>>;
}

const CoolebraPromoCards = ({ filters, setFilters, dataToShow }: CoolebraPromoCardsProps ) => {
  useAuth({ redirectIfNotLogged: true });
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1270px)");
  const [cardPacks, setCardPacks] = useState(16);
  const [seeMore, setSeeMore] = useState(true);

  const AddCardPack = () => {
    if (cardPacks <= dataToShow.length) {
      setCardPacks(cardPacks + 16);
    } else {
      setSeeMore(false);
    }
  };

  const RemoveCardPack = () => {
    setCardPacks(16);
    setSeeMore(true);
  };

  return (
    <>
      <div>
        {!isMobile && <FilterIconRow filters={filters} setFilters={setFilters}/>}
        <div className={classes.mainPromocontainer}>
          {dataToShow.slice(0, cardPacks).map((row: any) => {
            return <SingleCard key={row.id} cardData={row} />;
          })}
        </div>
        <div className={classes.seeMoreLessContainer}>
          <button
            className={
              seeMore
                ? classes.seeMoreLessButton
                : classes.seeMoreLessButtonDisabled
            }
            onClick={() => AddCardPack()}
          >
            ver mas
          </button>

          <button
            className={classes.seeMoreLessButton}
            onClick={() => RemoveCardPack()}
          >
            ver menos
          </button>
        </div>
      </div>
    </>
  );
};

export default CoolebraPromoCards;
