import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: "2rem",
    width: "10rem",
    borderRadius: "12px",
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    [theme.breakpoints.down(1270)]: {
      marginTop: "0rem",
    },
  },
  upper: {
    height: "7.5rem",
    backgroundColor: "#008296",
    borderRadius: "12px 12px 0 0",
  },
  promoImage: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    borderRadius: "10px 10px 0 0",
  },
  promoLogoContainer: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
    top: "-6.6rem",
    left: "0.4rem",
    borderRadius: "25px",
    height: "2.8rem",
    width: "2.8rem",
    background: "#EDEBEB",
  },
  promoLogo: {},
  lower: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: "0.25rem 0.25rem 0 0.25rem",
    gap: "0.3rem",
  },
  cardTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.625rem",
    fontWeight: "bold",
    color: "#393E44",
    marginTop: "0.25rem",
  },
  cardDescription: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.625rem",
    fontWeight: 500,
    color: "#9D9D9D",
    marginTop: "0rem",
    height: "3.8rem",
  },
  cardValidDate: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.5rem",
    fontWeight: 500,
    color: "#393E44",
    borderTop: "1px solid #F4F3F3",
    marginTop: "0.475rem",
  },
  weekDaysRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingBottom: "0.2rem",
  },
  weekdayEnabled: {
    backgroundColor: "#D6D6D6",
    borderRadius: "15px",
    width: "1.25rem",
    height: "1.25rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& span": {
      fontFamily: theme.typography.fontFamily,
      fontSize: "0.625rem",
      fontWeight: "bold",
      color: "#393E44",
    },
  },
  weekdayDisabled: {
    backgroundColor: "#none",
    border: "1px solid #D6D6D6",
    borderRadius: "15px",
    width: "1.25rem",
    height: "1.25rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& span": {
      fontFamily: theme.typography.fontFamily,
      fontSize: "0.625rem",
      fontWeight: "bold",
      color: "#D6D6D6",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "2rem",
    backgroundColor: "#F4F3F3",
    borderRadius: " 0 0 12px 12px",
    padding: "0 0.25rem 0 0.35rem",
  },
  bankTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.625rem",
    fontWeight: "bold",
    color: "#393E44",
  },
  bankLogo: {
    height: "1.1rem",
    maxWidth: "50%",
  },
  logos: {
    display: "flex",
    gap: "0.3rem",
  },
}));
