import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainPromocontainer: {
    width: "100%",
    display: "grid",
    gap: "0.75rem",
    gridTemplateColumns: "25% 25% 25% 25%",
    [theme.breakpoints.down(1270)]: {
      width: "100%",
      display: "grid",
      gap: "1rem",
      gridTemplateColumns: "50% 50%",
      marginTop: "1rem",
    },
  },
  iconRow: {
    height: "4rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "5.2rem",
  },
  icon: {
    height: "2rem",
  },
  iconRowText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.75rem",
    color: "#7D7C7C",
    textAlign: "center",
    marginTop: "0.25rem",
  },
  cardContainer: {
    height: "auto",
  },
  card: {
    height: "16.788rem",
    width: "9.813rem",
    backgroundColor: "green",
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    gap: "1rem",
  },
  seeMoreLessContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1rem 0rem 0 1rem",
    [theme.breakpoints.down(1270)]: {
      justifyContent: "left",
      gap: "3rem",
    },
  },
  seeMoreLessButton: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.75rem",
    color: "#7D7C7C",
    textDecoration: "underline",
    textDecorationColor: "#7D7C7C",
  },
  seeMoreLessButtonDisabled: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.75rem",
    color: "#D3D3D3",
    textDecoration: "underline",
    textDecorationColor: "#D3D3D3",
  },
}));
