import React, { useState, useEffect, useContext, useCallback } from "react";
import { PageProps } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { LayoutOnBoarding, SEO, ButtonPrimary } from "@components";
import CoolebraFilters from "@components/Coolebra/Filters/CoolebraFilters";
import CoolebraPromoCards from "@components/Coolebra/PromoCards/CoolebraPromoCards";
import { AppContext, ProfileContext, LoadingContext } from "@context";
import { useAuth } from "@hooks";
import Higher from "@components/Incentives/Higher/Higher";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
import { getCoolebraData } from "@apollo";
import { CoolebraFiltersTypes } from "@interfaces";

const incentives: React.FC<PageProps> = ({ location }) => {
  useAuth({ redirectIfNotLogged: true });
  const classes = useStyles();
  const isMobile = useMediaQuery("(min-width: 1270px)");
  const [filters, setFilters] = useState<CoolebraFiltersTypes>({
    values: [],
    days: [],
    categories: [],
  });
  const { coolebraData, refetchCoolebraData } = getCoolebraData(filters);
  const [textFilter, setTextFilter] = useState<string>();

  useEffect(() => {
    refetchCoolebraData();
  }, [filters]);

  const dataToShow = useCallback(() => {
    if (textFilter) {
      return coolebraData.filter((item) => {
        const titleText = item.title.toLowerCase();
        return titleText.includes(textFilter?.toLowerCase());
      });
    } else {
      return coolebraData;
    }
  }, [textFilter, filters, coolebraData]);

  useEffect(() => {
    const banks = location?.state?.selectedBanks;
    if (banks) {
      setFilters({ ...filters, values: banks });
    }
  }, [location]);

  return (
    <>
      <SEO
        title="Encuentra los descuentos de tus tarjetas - Rokin x Coolebra"
        description="¡Revisa todos los beneficios que tienes con tus tarjetas directamente desde Rokin! Encuentra descuentos en restaurantes, bencina, panoramas ¡y mucho más!"
      />
      {isMobile ? (
        <LayoutOnBoarding activeRokinpedia>
          <div className={classes.mainContainer}>
            <CoolebraFilters
              setTextFilter={setTextFilter}
              filters={filters}
              setFilters={setFilters}
              dataToShow={dataToShow()}
            />
            <CoolebraPromoCards
              filters={filters}
              setFilters={setFilters}
              dataToShow={dataToShow()}
            />
          </div>
        </LayoutOnBoarding>
      ) : (
        <LayoutMobileInvestments activeRokipedia>
          <>
            <CoolebraFilters
              setTextFilter={setTextFilter}
              filters={filters}
              setFilters={setFilters}
              dataToShow={dataToShow()}
            />
          </>
        </LayoutMobileInvestments>
      )}
    </>
  );
};

export default incentives;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: "5rem 0 5rem 0",
  },
}));
