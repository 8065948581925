import React, { useState, useEffect } from "react";
import { useAuth } from "@hooks";
import { useStyles } from "./SingleCard.style";

interface SingleCardProps {
  //couponNumber: number;
  cardData: any;
}

const SingleCard = ({ cardData }: SingleCardProps) => {
  console.log("🚀 « cardData:", cardData)
  useAuth({ redirectIfNotLogged: true });
  const classes = useStyles();
  const [openMonday, setOpenMonday] = useState(false);
  const [openTuesday, setOpenTuesday] = useState(false);
  const [openWednesday, setOpenWednesday] = useState(false);
  const [openThursday, setOpenThursday] = useState(false);
  const [openFriday, setOpenFriday] = useState(false);
  const [openSaturday, setOpenSaturday] = useState(false);
  const [openSunday, setOpenSunday] = useState(false);
  let weekdayString = cardData.week_days;
  useEffect(() => {
    setWeekdays();
  });
  const setWeekdays = () => {
    setOpenMonday(weekdayString.includes("1"));
    setOpenTuesday(weekdayString.includes("2"));
    setOpenWednesday(weekdayString.includes("3"));
    setOpenThursday(weekdayString.includes("4"));
    setOpenFriday(weekdayString.includes("5"));
    setOpenSaturday(weekdayString.includes("6"));
    setOpenSunday(weekdayString.includes("7"));
  };
  return (
    <>
      <a
        className={classes.card}
        href={cardData.url}
        target="_blank"
      >  
        <div className={classes.upper}>
          <img className={classes.promoImage} src={cardData.image} alt="" />
          <div className={classes.logos}>
            <div className={classes.promoLogoContainer}>
              <img
                className={classes.promoLogo}
                src={cardData.store.logo}
                alt=""
              />
            </div>
            {cardData.interm.logo && (
              <div className={classes.promoLogoContainer}>
                <img
                  className={classes.promoLogo}
                  src={cardData.interm.logo}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
        <div className={classes.lower}>
          <h2 className={classes.cardTitle}> {cardData.title}</h2>
          <p className={classes.cardDescription}>{cardData.description}</p>

          <p className={classes.cardValidDate}>{cardData.date_end}</p>

          <div className={classes.weekDaysRow}>
            <div
              className={
                openMonday ? classes.weekdayEnabled : classes.weekdayDisabled
              }
            >
              <span>L</span>
            </div>
            <div
              className={
                openTuesday ? classes.weekdayEnabled : classes.weekdayDisabled
              }
            >
              <span>M</span>
            </div>
            <div
              className={
                openWednesday ? classes.weekdayEnabled : classes.weekdayDisabled
              }
            >
              <span>M</span>
            </div>
            <div
              className={
                openThursday ? classes.weekdayEnabled : classes.weekdayDisabled
              }
            >
              <span>J</span>
            </div>
            <div
              className={
                openFriday ? classes.weekdayEnabled : classes.weekdayDisabled
              }
            >
              <span>V</span>
            </div>
            <div
              className={
                openSaturday ? classes.weekdayEnabled : classes.weekdayDisabled
              }
            >
              <span>S</span>
            </div>
            <div
              className={
                openSunday ? classes.weekdayEnabled : classes.weekdayDisabled
              }
            >
              <span>D</span>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <h4 className={classes.bankTitle}>{cardData.emitter.name}</h4>
          <img
            className={classes.bankLogo}
            src={cardData.emitter.logo}
            alt=""
          />
        </div>
      </a>
    </>
  );
};

export default SingleCard;
