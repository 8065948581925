import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainPromocontainer: {
    width: "100%",
    [theme.breakpoints.down(1270)]: {
      width: "auto",
    },
  },
  iconRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "0.2rem",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down(1270)]: {
      margin: "1rem 0 0 0",
    },
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "5.2rem",
  },
  icon: {
    height: "2rem",
  },
  iconRowText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.75rem",
    color: "#7D7C7C",
    textAlign: "center",
    marginTop: "0.25rem",
  },

  itemRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginTop: "2rem",
  },
}));
