import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, useMediaQuery } from '@material-ui/core';
import { useAuth } from '@hooks';
import { SearchBar } from '@components';
import { useStyles } from './CoolebraFilters.styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {
  COOLEBRA_HEADER,
  COOLEBRA_FILTRO_ICON,
  COOLEBRA_FILTRO_ICON_WHITE,
  COOLEBRA_IMAGE,
  COOLEBRA_LOGO_WHITE,
} from 'images/GCP';
import FilterIconRow from '../IconRow/FilterIconRow';
import CoolebraPromoCards from '../PromoCards/CoolebraPromoCards';
import {
  CoolebraFiltersTypes,
  CoolebraTypeAll,
} from 'interfaces/coolebraTypeAll';
import { getCoolebraBanksList } from '@apollo';
import { Checkbox, FormControlLabel } from '@mui/material';
import _ from 'lodash';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';

interface CoolebraProps {
  dataToShow: CoolebraTypeAll[];
  filters: CoolebraFiltersTypes;
  setFilters: Dispatch<SetStateAction<CoolebraFiltersTypes>>;
  setTextFilter: Dispatch<SetStateAction<string>>;
}

const CoolebraFilters = ({
  dataToShow,
  filters,
  setFilters,
  setTextFilter,
}: CoolebraProps) => {
  useAuth({ redirectIfNotLogged: true });
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1270px)');
  const { coolebraBanksList } = getCoolebraBanksList();

  const [openFilters, setOpenFilters] = useState(true);
  const [openFiltersMobile, setOpenFiltersMobile] = useState(false);
  const [openFiltroProductos, setOpenFiltroProductos] = useState(true);
  const [openFiltroDiasSemana, setOpenFiltroDiasSemana] = useState(true);
  const [openFiltroCategorias, setOpenFiltroCategorias] = useState(true);
  const [reload, setReload] = useState<boolean>(false);
  //BANKS FILTERS
  const [banks, setBanks] = useState<string[]>([]);
  const [banksShowMore, setBanksShowMore] = useState<boolean>(false);
  const handleShowMoreBanks = () => {
    setBanksShowMore(!banksShowMore);
  };

  //DAYS FILTERS
  const [days, setDays] = useState<number[]>([]);
  const weekDays = {
    Lunes: 1,
    Martes: 2,
    Miércoles: 3,
    Jueves: 4,
    Viernes: 5,
    Sábado: 6,
    Domingo: 7,
  };

  //CATEGORIES FILTERS
  const [categories, setCategories] = useState<string[]>([]);
  const coolebraCategories = {
    Sabores: 'sabor',
    Servicios: 'serv',
    Shopping: 'shop',
    Promociones: 'promo',
    'Salud y Belleza': 'salud',
    Panoramas: 'pano',
    Viajes: 'viaje',
  };

  const separatorComp = () => {
    return (
      <div className={classes.vectorContainer}>
        <div className={classes.vector}></div>
      </div>
    );
  };

  const filtersComponent = () => {
    return (
      <>
        {separatorComp()}
        <button
          className={classes.productRow}
          onClick={() => {
            setOpenFiltroProductos(!openFiltroProductos);
          }}
        >
          <p className={classes.filterTitle}>PRODUCTOS</p>
          <KeyboardArrowDownRoundedIcon
            htmlColor="#9D9D9D"
            className={
              openFiltroProductos
                ? classes.collapsableIconOpen
                : classes.collapsableIcon
            }
          />
        </button>
        <div
          className={
            openFiltroProductos ? classes.filtersColumn : classes.hidden
          }
        >
          {coolebraBanksList
            .slice(0, banksShowMore ? coolebraBanksList.length : 10)
            .map((row: any) => {
              return (
                <FormControlLabel
                  key={row}
                  className={classes.filtersRow}
                  control={
                    <Checkbox
                      className={classes.CheckboxInput}
                      size="small"
                      checked={filters.values.some((item) =>
                        row.toLowerCase().includes(item.toLowerCase())
                      )}
                      onChange={(e) => [
                        e.target.checked
                          ? setBanks([...banks, row])
                          : setBanks(banks.filter((item) => item !== row)),
                        setReload(true),
                      ]}
                    />
                  }
                  label={row}
                />
              );
            })}
          <button
            className={classes.moreText}
            onClick={() => handleShowMoreBanks()}
          >
            {banksShowMore ? 'ver menos' : 'ver más'}
          </button>
        </div>
        {separatorComp()}
        <button
          className={classes.productRow}
          onClick={() => {
            setOpenFiltroDiasSemana(!openFiltroDiasSemana);
          }}
        >
          <p className={classes.filterTitle}>DÍAS DE LA SEMANA</p>
          <KeyboardArrowDownRoundedIcon
            htmlColor="#9D9D9D"
            className={
              openFiltroDiasSemana
                ? classes.collapsableIconOpen
                : classes.collapsableIcon
            }
          />
        </button>
        <div
          className={
            openFiltroDiasSemana ? classes.weekdaysContainer : classes.hidden
          }
        >
          {Object.entries(weekDays).map((day) => {
            return (
              <Button
                className={classes.weekdayButton}
                color="primary"
                variant={
                  days.find((item) => item === day[1])
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => [
                  days.find((item) => item === day[1])
                    ? setDays(days.filter((item) => item !== day[1]))
                    : setDays([...days, day[1]]),
                  setReload(true),
                ]}
              >
                {day[0]}
              </Button>
            );
          })}
        </div>
        {separatorComp()}
        <button
          className={classes.productRow}
          onClick={() => {
            setOpenFiltroCategorias(!openFiltroCategorias);
          }}
        >
          <p className={classes.filterTitle}>CATEGORIAS</p>
          <KeyboardArrowDownRoundedIcon
            htmlColor="#9D9D9D"
            className={
              openFiltroCategorias
                ? classes.collapsableIconOpen
                : classes.collapsableIcon
            }
          />
        </button>
        <div
          className={
            openFiltroCategorias ? classes.filtersColumn : classes.hidden
          }
        >
          {Object.entries(coolebraCategories).map((row: any) => {
            return (
              <FormControlLabel
                key={row[1]}
                className={classes.filtersRow}
                control={
                  <Checkbox
                    className={classes.CheckboxInput}
                    size="small"
                    checked={filters.categories.some((item) => item === row[1])}
                    onChange={(e) => [
                      e.target.checked
                        ? setCategories([...categories, row[1]])
                        : setCategories(
                            categories.filter((item) => item !== row[1])
                          ),
                      setReload(true),
                    ]}
                  />
                }
                label={row[0]}
              />
            );
          })}
        </div>
        {separatorComp()}
      </>
    );
  };

  useEffect(() => {
    if (reload) {
      setFilters({
        values: banks,
        days: days,
        categories: categories,
      });
    }
    setReload(false);
  }, [reload]);

  return (
    <>
      <div className={classes.mainFiltersContainer}>
        <div
          className={openFiltersMobile ? classes.mobileFilters : classes.hidden}
        >
          <div className={classes.upperMobileFilter}>
            <button
              className={classes.backButtonFilterMobile}
              onClick={() => setOpenFiltersMobile(!openFiltersMobile)}
            >
              <CloseRoundedIcon
                className={classes.leftArrow}
                htmlColor="#FFFFFF"
                fontSize="inherit"
              />
            </button>
            <div className={classes.FilterTitleMobile}>
              <img src={COOLEBRA_FILTRO_ICON_WHITE} alt="" />
              <span>Filters</span>
            </div>
          </div>
          <div className={classes.LowerMobileFilter}>
            {filtersComponent()}
            <Button
              className={classes.applyButton}
              color="primary"
              variant="contained"
              onClick={() => setOpenFiltersMobile(!openFiltersMobile)}
            >
              Aplicar
            </Button>
          </div>
        </div>
        <div className={classes.upper}>
          <img className="coolebraImage" src={COOLEBRA_IMAGE} alt="" />
          <a href={'https://www.coolebra.com/'} target="_blank">
            <img className="coolebraLogo" src={COOLEBRA_LOGO_WHITE} alt="" />
          </a>
          <button
            className={classes.backButton}
            onClick={() => navigate(Route.finance)}
          >
            <KeyboardArrowLeftRoundedIcon
              className={classes.leftArrow}
              htmlColor="#FFFFFF"
              fontSize="inherit"
            />
          </button>
        </div>
        <div className={classes.lower}>
          <h1 className={classes.title}>Coolebra</h1>
          <h2 className={classes.description}>
            Encuentra los descuentos de tus tarjetas del 1 al 31 de marzo
          </h2>
          <SearchBar onChange={(e) => setTextFilter(e.target.value)} />
          {isMobile && (
            <FilterIconRow filters={filters} setFilters={setFilters} />
          )}
          <div className={classes.vectorContainer}>
            <div className={classes.vector}></div>
          </div>
          <button
            className={classes.filterButtonContainer}
            onClick={() => {
              isMobile
                ? setOpenFiltersMobile(!openFiltersMobile)
                : setOpenFilters(!openFilters);
            }}
          >
            <img src={COOLEBRA_FILTRO_ICON} alt="" />
            <p className={classes.filterTitle}>Filters</p>
            <KeyboardArrowRightRoundedIcon
              htmlColor="#9D9D9D"
              className={
                openFilters
                  ? classes.collapsableMainIconOpen
                  : classes.collapsableIconOpen
              }
            />
          </button>
          {openFilters && !isMobile && filtersComponent()}
          {isMobile && !openFiltersMobile && (
            <CoolebraPromoCards
              filters={filters}
              setFilters={setFilters}
              dataToShow={dataToShow}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CoolebraFilters;
